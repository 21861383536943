<template>
    <div>
        <b-card no-body class="card-statistics"  v-for="item in statisticsItems" :key="item.icon"> 
        <b-card-body class="statistics-body">
            <b-row>
                <b-col xl="4" sm="6" :class="item.customClass">
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar size="48" :variant="item.color">
                                <feather-icon size="24" :icon="item.icon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ item.title }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                {{ item.subtitle }}
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
    </div>
</template>

<script>
    import {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BRow,
        BCol,
        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,
    } from 'bootstrap-vue'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BCardHeader,
            BCardTitle,
            BCardText,
            BCardBody,
            BMedia,
            BAvatar,
            BMediaAside,
            BMediaBody,
        },
        props: {
            data: {
                type: Array,
                default: () => [],

            },
        },
        data() {
            return {
                statisticsItems: [{
                        icon: 'TrendingUpIcon',
                        color: 'light-primary',
                        title: '230k',
                        subtitle: '  Nilai Anggaran ',
                        customClass: 'mb-2 mb-xl-0',
                    },
                    {
                        icon: 'UserIcon',
                        color: 'light-info',
                        title: '8.549k',
                        subtitle: '  Nilai Kontrak',
                        customClass: 'mb-2 mb-xl-0',
                    },
                    {
                        icon: 'BoxIcon',
                        color: 'light-danger',
                        title: '1.423k',
                        subtitle: 'Serapan Anggaran',
                        customClass: 'mb-2 mb-sm-0',
                    }, 
                ],
            }
        }
    }
</script>